import React from 'react'
import ServiceCard from "./ServiceCard"
import Glass from "../../images/glasscutting.jpg"
import Lock from "../../images/lock.jpg"
import Timber from "../../images/timbercutting.jpg"
import Flyscreen from "../../images/flyscreen_cropped.png"

const Services = () => {
    return (
        <div className="pt-10 px-4 mt-2 pb-2 md:px-8 lg:mt-8 xl:max-w-6xl xl:self-center xl:px-6">
             <h2 className="text-xl font-customHeader">Services We Provide</h2>
             <div className="flex flex-col md:flex-row md:flex-wrap">
             <ServiceCard image={Glass} title="Glass Cutting" description="Need glass cut to fit a custom size for your windows? No problem, come visit us in-store to arrange glass cutting for you."/>
             <ServiceCard image={Flyscreen} title="Flyscreen Replacement" description="We provide flyscreen replacement service for your window or door frame. Just bring your frame."/>
             <ServiceCard image={Lock} title="Security Door Lock Repair" description="Jammed your lock or broke a piece? Not to worry, bring your old lock to our store, and one of our specialists will help you repair it."/>
             <ServiceCard title="Timber / Metal Cutting" description="For a minimal fee, we can help you cut timber or metal spans. This is only for short lengths and not for any industrial cutting." image={Timber}/>
             </div>
             
        </div>
    )
}

export default Services
