import React from 'react'
import { Link } from "gatsby"

const FooterCta = () => {
    return (
        <div className="mx-4 sm:max-w-full  mt-10 my-10 flex md:flex md:justify-center lg:mt-16 xl:max-w-6xl xl:self-center xl:w-full ">
        <div className="flex  bg-primary sm:w-full md:w-3/4 items-center md:py-4 lg:p-8">
            <div className="flex-1 py-4 pl-6 text-gray-100 font-sans">
            <p className="my-2 font-light font-customBody  md:text-xl">Don't see what you're looking for?</p>
            <p className="my-2 font-light font-customBody  md:text-xl">No Problem, just call us or send an message!</p>
            </div>
           
            <div className="flex-1 flex flex-col">
                <button className="w-3/4 self-center  my-2 p-2 bg-gray-900 text-gray-100 font-customHeader  cursor-pointer hover:bg-gray-800 hover:text-gray-100 focus:outline-none focus:shadow-outline active:bg-gray-500 md:text-lg lg:text-lg md:p-3"><Link to='/contact'>Call Us</Link></button>
                <button className="w-3/4 self-center font-customHeader  bg-secondary my-2 p-2 cursor-pointer hover:bg-gray-800 hover:text-gray-100 focus:outline-none focus:shadow-outline active:bg-teal-500 md:text-lg lg:text-lg md:p-3"><Link to='/contact'>Email Us</Link></button>
            </div>
        </div>
        </div>
    )
}

export default FooterCta
