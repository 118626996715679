import React from 'react'
import CategoryCard from './CategoryCard'
import Padlock from "../../images/Category Icons/padlock.svg"
import Pipe from "../../images/Category Icons/pipe.svg"
import Tools from "../../images/Category Icons/hammer.svg"
import Screw from "../../images/Category Icons/screw.svg"
import Paint from "../../images/Category Icons/paint-brush.svg"
import Garden from "../../images/Category Icons/watering-can.svg"
import Craft from "../../images/Category Icons/crafts.svg"
import Cement from "../../images/Category Icons/cement.svg"
import Lighting from "../../images/Category Icons/light-bulb.svg"
import Electrical from "../../images/Category Icons/power-strip.svg"
import Key from "../../images/Category Icons/key.svg"
import Shower from "../../images/Category Icons/shower.svg"

const ProductCategories = () => {
    return (
        <div className="relative pt-10 px-4 mt-10 pb-2 md:mt-24 md:px-8 lg:mt-40 xl:max-w-6xl xl:self-center xl:w-full xl:mt-48 xl:px-6 ">
            <h2 className="text-xl font-customHeader">Product Categories</h2>
            <div className="flex  flex-wrap ">
            <CategoryCard categoryName="Door Hardware" icon={Padlock} />
            <CategoryCard categoryName="Plumbing & gas" icon={Pipe} />
            <CategoryCard categoryName="Hand Tools" icon={Tools} />
            <CategoryCard categoryName="Bolts and Nuts" icon={Screw} />
            <CategoryCard categoryName="Paint" icon={Paint} />
            <CategoryCard categoryName="Garden" icon={Garden} />
            <CategoryCard categoryName="Adhesive & Glues" icon={Craft} />
            <CategoryCard categoryName="Cement & Concrete" icon={Cement} />
            <CategoryCard categoryName="Electrical" icon={Electrical} />
            <CategoryCard categoryName="Key Cutting" icon={Key} />
            <CategoryCard categoryName="Home Lighting" icon={Lighting} />
            <CategoryCard categoryName="Kitchen & Shower" icon={Shower} />

            </div>
  
        </div>
    )
}

export default ProductCategories
