import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Home/Hero";
import ProductCategories from "../components/Home/ProductCategories"
import Services from "../components/Home/Services"
import FooterCta from "../components/Home/FooterCta"

const IndexPage = () => {

  return (
    
  <Layout>
    <SEO title="Home" />
      <Hero />   
      <ProductCategories />
      <Services />
      <FooterCta />
  </Layout>  

     )
}


export default IndexPage
