import React from 'react'

const CategoryCard = ({categoryName, icon}) => {
    return (
        <div className="w-1/3 pr-2 my-1 md:w-1/4 md:my-1  lg:w-1/5 xl:w-1/6 ">
            <div className="flex flex-col justify-center border-solid 
        border-indigo-300 border rounded bg-offwhite py-4 px-2 h-full xl:p-8">
            <img className="justify-center self-center max-w-1/2 h-8 xl:h-12" src={icon} alt={categoryName} />
            <p className="m-0 text-sm xl:text-base font-bold text-blue-500 font-customBody text-left flex-1">{categoryName}</p>  
            </div>
            
        </div>
    )
}

export default CategoryCard
