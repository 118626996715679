import React from 'react'

const ServiceCard = ({title, description, image}) => {
    return (
        <div className="w-full md:pr-4 pb-4 flex md:w-1/2">
            <div className=" border-solid border border-darkred bg-lightredshade rounded flex py-6 px-2">
                <img className="w-1/3 mr-2 object-cover h-full " src={image} alt={title} />
                <div className="w-2/3 xl:ml-4 px-2">
                <h3 className="text-base font-customBody font-bold">{title}</h3>
                <p className="text-sm font-customBody  font-darkred">{description}</p>
            </div>
            </div>
            
        </div>
    )
}

export default ServiceCard
