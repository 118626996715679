import React from 'react'
import VectorHero from "../../images/Group.svg"
import { Link } from "gatsby"

const Hero = () => {
    return (
        <div className="flex flex-col  ">
            <div className="relative w-full xl:max-w-6xl">
            <div className="max-w-3/4 mt-8 px-4 md:w-4/5 md:pl-6 w-full lg:mt-10  xl:mt-16  xl:max-w-6xl xl:pl-6 z-5 relative">
                <h1 className="text-xl sm:text-lg lg:text-3xl text-gray-200 md:w-full md:text-xl md:mt-5 font-customHeader">Your Local Family Hardware Store</h1>
                <h3 className="text-base text-gray-200 font-customBody font-light w-3/4 sm:max-w-1/2 md:max-w-full lg:text-lg">We Stock Hard to Find Products.</h3>
                <button className="bg-secondary hover:bg-gray-800 hover:text-gray-100 focus:outline-none focus:shadow-outline active:bg-teal-500 p-4 text-gray-900 text-base font-bold cursor-pointer shadow font-customHeader lg:text-xl"><Link to='/contact'>Contact Us</Link></button>
            </div>
            <img className="absolute z-5 right-0 mr-2 -mt-40 md:-mt-20p xl:-mt-17p w-40  md:w-30 md:mr-6 lg:w-30  xl:w-1/4 " src={VectorHero} alt="Hero Image" />
            </div>
            <svg className="z-0 absolute sm:absolute top-0  sm:block mt-8 w-full md:hidden lg:hidden xl:hidden" viewBox="0 0 375 372"  fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M206.5 106C132.98 161.426 42.8333 133.166 -1 97.6105V333.779C5.33333 343.966 33.644 365.025 82 370.333C147.5 377.524 210.5 363.142 277.5 293.629C331.1 238.019 354.667 200.546 375 203.742V-3H283C282.167 14.3783 268.5 59.2584 206.5 106Z" fill="#0061F3"/>
            </svg>

            <svg className="z-0 absolute top-0 mt-8 w-full hidden sm:hidden md:block lg:block lg:mt-0 lg:mr-4 xl:hidden " viewBox="0 0 834 470" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M237.414 446.027C151.212 473.564 -39.8682 489.053 -124.633 425.949V311.215L-139 131.944C-110.266 281.098 -156.24 83.7556 -110.266 103.26C-52.7985 127.641 -23.0599 147.074 77.9407 139.115C187.129 130.51 292.008 38.7226 312.122 8.60503L973 0V269.624C964.38 268.19 929.325 266.182 858.065 269.624C768.99 273.927 576.474 316.952 511.822 344.201C447.171 371.451 345.166 411.607 237.414 446.027Z" fill="#0061F3"/>
            </svg>

            <svg className="z-0 absolute top-0 hidden sm:hidden md:hidden lg:hidden xl:block w-full xl:mt-2 xl:max-w-6xl " viewBox="0 0 1440 637" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M462.965 610.089C342.714 648.494 118.246 655.095 3.82266e-06 567.082L0 143.021C80.167 177.026 99.6061 193.128 240.501 182.026C392.818 170.025 539.123 42.0061 567.182 0H1440V362C1267.23 374.947 935.95 430.062 845.762 468.068C755.574 506.073 613.278 562.082 462.965 610.089Z" fill="#0061F3"/>
            </svg>
            
          
        </div>
    )
}

export default Hero
